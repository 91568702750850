@use "mq";

.project {
  &__content {
    > * {
      margin-top: 0;
    }
  }
  
  &__header {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.4em;
  }

  &__title {
    display: inline;

    &::after {
      content: ", ";
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.4em;

    > * {
      display: inline;
      
      &:not(:last-child)::after {
        content: ", "
      }
    }
  }
}

/**
* Position the project gallery wrapper on project pages.
*
*/
body[data-view="project"] {
  section#projects {
    display: flex;
    gap: var(--gap-vertical);
    flex-direction: column;

    .section__content-wrapper {
      position: relative;
      flex-grow: 1;
      min-height: 16em;

      @include mq.mq($until: small) {
        left: -38vw;
        width: calc(38vw + 100%);
      }
    }

    .gallery {
      position: absolute;
      top: 0;
      left: 0; 
      right: 0;
      bottom: 0;
    }
  }

  .widget {
      //display: none;
  }

  section#about .section__content-wrapper {
    display: none;
  }
}
