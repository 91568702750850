#contact {
  display: flex;
  gap: 0.35em; 

  > * {
    &:not(:last-child) {
      &::after {
        content: ", ";
      }
    }
  }
}
