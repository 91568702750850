@use "mq";

.gallery {
  margin-top: 2em;

  @include mq.mq($until: small) {
    margin-bottom: 4em;
  }

  @include mq.mq($from: small) {
    margin-bottom: 2em;
  }

  &__inner {
    height: 100%;
  }

  &__wrapper {
    align-items: stretch;
  }

  &__item {
    height: auto;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
    background: rgba(255, 255, 255, .1);
  }

  &__button {
    cursor: pointer;
    text-transform: uppercase;

    @include mq.mq($until: small) {
      font-size: 0.83em;
      margin-top: calc(1em * var(--line-height));
    }
    
    @include mq.mq($from: small) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 1.35em;
      width: 1.35em;
      border-radius: 50%;
      box-shadow: inset 0 0.33em 0.2em rgba(0, 0, 0, 0.16);
    }

    &--prev {
      @include mq.mq($until: small) {
        &::before {
          content: "Prev";
        }
      }

      @include mq.mq($from: small) {
        left: -11vw;
      }
    }

    &--next {
      @include mq.mq($until: small) {
        position: absolute;
        right: 0;

        &::before {
          content: "Next";
        }
      }

      @include mq.mq($from: small) {
        right: -11vw;
      } 
    }

    &--disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }
}
