@use "mq";

#projects {
  .section__title {
    &:hover {
      filter: invert(1);
      mix-blend-mode: difference;
    }

    &::before {
      content: "(";
    }

    &::after {
      content: ")";
    }
  }

  .section__content[data-barba-namespace="projects"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 0;
  }
}

.projects {
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 3.4em 0;

  @include mq.mq($until: small) {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 4.36%;
    row-gap: 0.9em;
  }

  @include mq.mq($from: small) {
    grid-template-columns: repeat(6, 1fr);
    column-gap: 6.6%;
    row-gap: 3.16em;
    padding-left: 3vw;
    padding-right: 3vw;
  }

  &__item {
    width: 100%;
    aspect-ratio: 1 / 1.14;
    border-radius: 50%;
    background: var(--project-color);

    &:not([style]) {
      display: none;
    }

    > a {
      display: block;
      width: 100%;
      height: 100%;
    }

    @include mq.mq($until: small) {
      filter: blur(1.34vw);
    }
  
    @include mq.mq($from: small) {
      filter: blur(0.91vw);
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;

    &:not(:hover) {
      mix-blend-mode: luminosity;
    }
  }

  &--empty {
    display: block;

    img {
      object-position: top left;
      mix-blend-mode: initial !important;
      height: auto;
      max-height: 50vh;
    }
  }
}

/**
* Position projects list container on projects index
*
*/
body[data-view="projects"] {
  main {

  }
  
  section#about .section__content-wrapper {
    display: none;
  }

  section#projects {
    grid-row: 3 / 5;
    pointer-events: none;

    @include mq.mq($until: small) {
      grid-row: 3 / 5;
    }
  
    @include mq.mq($from: small) {
      grid-row: 2 / 4;
      grid-column: 1 / 3;
    }

    * {
      pointer-events: inherit;
    }

    .section__title, 
    .projects__item {
      pointer-events: auto;
    }
  }
}
