@use "mq";

body, 
html {
  height: 100%;
  margin: 0;
}

* {
  font-size: 1em;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

body {
  font-family: "Dia", sans-serif;
  line-height: var(--line-height);
  color: var(--color--foreground);
  background: var(--color--background);

  @include mq.mq($from: small) {
    font-size: 135%;
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: #0000ff;
  }
}

a, 
button {
  margin: -0.25em;
  padding: 0.25em;
}

main {
  display: grid;
  row-gap: var(--gap-vertical);
  column-gap: 6.5vw;
  counter-reset: page-section 0;
  min-height: 100%;
  padding: var(--page-margin);
  box-sizing: border-box;

  transition-property: grid-template-rows;
  transition-duration: 200ms;

  @include mq.mq($until: small) {
    grid-template-rows: 0fr 0fr auto 0fr 0fr;
    grid-template-columns: 100%;
  }

  @include mq.mq($from: small) {
    grid-template-rows: 0fr auto 0fr;
    grid-template-columns: minmax(0, 3fr) auto; // Minmax fixes issue with swiper gallery not knowing column width
    padding: var(--page-margin) ;
  }
}

section {
  position: relative;  
  counter-increment: page-section 1;

  @include mq.mq($until: small) {
    grid-column: 1 / 2;
    padding-left: 38vw;
  }

  @include mq.mq($from: small) {
    padding-left: 13.5vw;
  }

  &::before {
    font-size: var(--font-size--large);
    position: absolute;
    left: 0;
    top: 0;
    content: counter(page-section);
    z-index: 5;

    @include mq.mq($from: small) {
      left: 3vw;
    }
  }
}

.section {
  &__title {
    position: relative;
    font-size: var(--font-size--large);
    text-transform: uppercase;
    z-index: 5;
  }

  &__content {

  }

  &__title + &__content {
    padding-top: var(--gap-vertical);
  }

  &:not(#projects) {
    .section__content-wrapper {
      position: relative;
    }
  }
}

.content {
  & > * + * {
    margin-top: 1em;
  }
}

section#about {
  @include mq.mq($until: small) {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  @include mq.mq($from: small) {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  .section__content {
    padding-top: var(--gap-vertical);
    padding-bottom: calc(var(--line-height) * 2em);

    > p + p {
      margin-top: 1em;
    }
  }
}

section#contact {
  @include mq.mq($until: small) {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }

  @include mq.mq($from: small) {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    counter-reset: page-section 3;
  }
}

section#projects {
  @include mq.mq($until: small) {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
  }

  @include mq.mq($from: small) {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    counter-reset: page-section 1;
  }
}

section#content {
  align-self: end;

  @include mq.mq($until: small) {
    grid-row: 4 / 5;
    grid-column: 1 / 2;
  }

  @include mq.mq($from: small) {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
    counter-reset: page-section 2;
  }

  .section__content {
    > * {
      min-height: calc(var(--line-height) * 4em);
    }
  }
}

section#time {
  align-self: end;
  
  @include mq.mq($until: small) {
    grid-row: 5 / 6;
    grid-column: 1 / 2;
    padding-top: 2em;
    padding-bottom: 0.5em;
  }

  @include mq.mq($from: small) {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
  }

  > * {
    font-size: var(--font-size--large);
  }

  &::before {
    content: "";
  }
}

body[data-view="home"] {
  main {

  }
}
